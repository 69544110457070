export const RESOURCE = 1; //资源下载
export const SERVICE = 2; //服务门店
export const BRAND = 3; //品牌

// 协议相关常量
export const USER = "user"; // 用户协议
export const DISCLAIMER = "disclaimer"; // 免责声明
export const PLATFORM = "platform"; // 平台规则
export const OTHER = "other"; // 其它协议
export const PRIVACY = "privacy"; // 隐私协议
